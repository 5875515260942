import React from "react";
import { Helmet } from "react-helmet";
import AboutUsIllustration from '../Assets/Images/about_us_.svg';
import { aboutUsData } from '../Assets/Utils/HomePageData';
const AboutUs = ({ boxRef }) => {
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.antphy.com/about-us" />
                <meta
                    name="description"
                    content="Ant Philosophy is a product centric company that strives to own the way technology should work and be adopted for real world applications."
                />
            </Helmet>
            <div className="aboutUs bg-blur pt-20">
                <div className="  flex justify-center items-center " ref={boxRef}>
                    <img
                        src={AboutUsIllustration}
                        alt="About Us Illustration"
                        className="aboutUs-illus"
                        height={500}
                        width={300}
                    />
                </div>
                <div className="aboutUs-text">
                    <div>
                        <p className="title-white  aboutUs-title title-padding">
                            {aboutUsData.TITLE}
                        </p>
                        <p className=" para-white ">{aboutUsData.P1}</p>
                        <p className=" para-white body-padding ">{aboutUsData.P2}</p>
                        {/* <p className="para-white pt-10 aboutUs-para">{aboutUsData.P3}</p> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs;