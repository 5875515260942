import React, { useEffect, useRef } from 'react';
import {
	blockchainData,
	blockchainServicesData,
} from '../Assets/Utils/ServicePageData';
import blockchainIllustartion from '../Assets/Images/blockchain_illus.svg';
import smartContract from '../Assets/Images/smart_supply_chain.svg';
import iotIllus from '../Assets/Images/iot.svg';
import identityAuth from '../Assets/Images/identity_authentication.svg';
import digitalWallet from '../Assets/Images/digital_wallet.svg';
import deFi from '../Assets/Images/DeFi.svg';
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Helmet } from 'react-helmet-async';
// import identityAuth from '../Assets/Images/identity_authentication.svg';
export default function Blockchain() {
	const one = useRef(null);
	const blockchain = useRef(null);
	const blockchainIllus = useRef(null);
	const blockchain1 = useRef(null);
	const blockchain2 = useRef(null);
	const blockchain3 = useRef(null);
	const blockchain4 = useRef(null);
	const blockchain5 = useRef(null);
	gsap.registerPlugin(ScrollTrigger);

	const tl = gsap.timeline();

	useEffect(() => {
		window.scrollTo(0, 0);

		tl.from(one.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: one.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});

		tl.from(blockchainIllus.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchainIllus.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain1.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain1.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain2.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain2.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain3.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain3.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain4.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain4.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain5.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain5.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
	}, []);

	return (
		<>
			<Helmet>
				<title>Blockchain</title>
				<link rel="canonical" href="https://www.antphy.com/blockchain" />
				<meta
					name='description'
					content='In the modern day digital estates, dominated by data, the need for Transparency and Traceability along with robust security is invaluable and imperative.'
				/>
			</Helmet>
		<div
			className=" flex justify-center items-center flex-col  pb-20  bg-blur bg-bgBlack mt-20"
			id="blockchain"
		>
			<img src={blockchainIllustartion} alt="Blockchain Illustration" ref={blockchainIllus} />
			<div className=" flex flex-col justify-center items-center">
				<p className=" num" ref={one}>
					01
				</p>
				<p className=" title-white pt-10 pb-10">{blockchainData.TITLE}</p>
			</div>
			<div className=" w-3/4 text-center">
				<p className=" para-white ">{blockchainData.P1}</p>
				<p className="para-white body-padding ">{blockchainData.P2}</p>
				<p className="para-white body-padding">{blockchainData.P3}</p>
			</div>

			{/* blockchain service */}

			<div className=" w-full ">
				<div className=" flex flex-col justify-center items-center">
					<p className=" para-blue pt-10 pb-10">
						{blockchainServicesData.TITLE}
					</p>
				</div>
				<div className=" w-full flex flex-col justify-center items-center">
					<div className=" service relative" ref={blockchain1}>
						<img src={smartContract} alt="Smart Contracts Illustration" className=" service-illus" />
						<p className="para-white text-center">
							{blockchainServicesData.SERVICE1}
						</p>
					</div>
					<div className=" service mt-5 relative" ref={blockchain2}>
						<img src={deFi} alt="DeFi Illustration" className=" service-illus pr-5 pl-5" />
						<p className="para-white text-center">
							{blockchainServicesData.SERVICE2}
						</p>
					</div>
					<div className=" service mt-5 relative" ref={blockchain3}>
						<img
							src={digitalWallet}
							alt="Digital Wallet"
							className=" service-illus pr-5 pl-5"
						/>

						<p className="para-white">{blockchainServicesData.SERVICE3}</p>
					</div>
					<div className=" service mt-5 relative" ref={blockchain4}>
						<img
							src={identityAuth}
							alt="Identity Auth"
							className=" service-illus pr-5 pl-5"
						/>
						<p className=" para-white">
							{blockchainServicesData.SERVICE4.TITLE}
						</p>
						<div className=" flex justify-start items-start flex-col">
							<p className=" para-white blockchain-sevice4">
								{blockchainServicesData.SERVICE4.ACCESS}
							</p>
							<p className=" para-white list-item text-center ">
								{blockchainServicesData.SERVICE4.DIGITAL}
							</p>
						</div>
					</div>
					<div className=" service mt-5 relative" ref={blockchain5}>
						<img src={iotIllus} alt="IOT Illustration" className=" service-illus " />
						<p className=" para-white text-center">
							{blockchainServicesData.SERVICE5}
						</p>
					</div>
				</div>
			</div>
			</div>
			</>
	);
}
