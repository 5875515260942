import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
// import { browserHistory } from 'react-router';

import Services from './Pages/Services';
import Navbar from './Assets/Components/Navbar';
import Footer from './Assets/Components/Footer';
import Industries from './Pages/Industries';
import Products from './Pages/Products';
import Contact from './Pages/Contact';
import { Helmet } from 'react-helmet';
import { aboutUsData } from './Assets/Utils/HomePageData';
import AboutUsIllustration from './Assets/Images/aboutus4x.png';
import { useRef } from 'react';
import Blockchain from './Pages/Blockchain';
import DataAi from './Pages/DataAi';
import SystemIntegration from './Pages/SystemIntegration';

function App() {
	const elementRef = useRef(null);
	const handelClick = () => {
		elementRef.current.scrollIntoView();
	};
	return (
    <BrowserRouter>
      {/* <Helmet>
				<title>Home page</title>
			</Helmet> */}
      <Navbar handelClick={handelClick} />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home elementRef={elementRef} />} />
        <Route path="/about-us" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/industry" element={<Industries />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/blockchain" element={<Blockchain />} />
        <Route path="/data-ai" element={<DataAi />} />
        <Route path="/system-integrations" element={<SystemIntegration />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
