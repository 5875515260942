import React, { useEffect, useRef } from 'react';
// import '../Assets/Styles/Global.css';
import '../Assets/Styles/Services.css';
import productIllustration from '../Assets/Images/Product_Illustration.png';
import { productData } from '../Assets/Utils/ProductsPageData';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap, Power3 } from 'gsap';
import { Helmet } from 'react-helmet-async';

function Products() {
	const prductIllus = useRef(null);
	const productTitle = useRef(null);
	gsap.registerPlugin(ScrollTrigger);
	const tl = gsap.timeline();
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top when the component mounts

		tl.from(prductIllus.current, 1, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
		});
		tl.from(productTitle.current, 1, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
		});
	}, []);
	return (
		<>
			<Helmet>
				<title>Products</title>
				<link rel="canonical" href="https://www.antphy.com/products" />
				<meta
					name='description'
					content='Our products are getting built in stealth mode. Watch this space for some exciting announcements and more info on our products.'
				/>
			</Helmet>
		<div className=" bg-bgBlack overflow-hidden">
			<div
				className=" bg-blur flex justify-center items-center pt-52 "
				ref={prductIllus}
			>
				<img src={productIllustration} alt="Product Illustration" />
			</div>
			<div
				className=" flex flex-col justify-center items-center w-full"
				ref={productTitle}
			>
				<p className=" w-3/4  para-white text-center pt-10 pb-20">
					{productData.P1}
				</p>
			</div>
			</div>
			</>
	);
}

export default Products;
