const serivceTitle = {
	TITLE: 'WE HELP BUSINESS TO SCALE & TRANSFORM THROUGH OUR SERVICES',
};

const blockchainData = {
	TITLE: 'BLOCKCHAIN',
	P1: 'In the modern day digital estates, dominated by data, the need for Transparency and Traceability along with robust security is invaluable and imperative.',
	P2: 'Blockchain technology has outgrown its initial purposes of cryptocurrencies and now is a revolutionary and disruptive leader with various technological use cases across multiple industry verticals.',
	P3: 'The underlying decentralized architecture and layered with cryptographic security, makes Blockchain an ideal choice for Enterprise Applications and Workflows that require enterprise Chain-of-Custody, Decentralization, Traceability and Security.',
};

const blockchainServicesData = {
	TITLE: 'Our current service use cases include,',
	SERVICE1: 'Smart Contracts & Supply Chain Management',
	SERVICE2:
		'Decentralized Finance (DeFi) / Decentralized Autonomous Organizations (DAO)',
	SERVICE3: 'Digital Wallets',
	SERVICE4: {
		TITLE: 'Identity and Authentication',
		DIGITAL: 'Digital Identity',
		ACCESS: 'Access management',
	},
	SERVICE5: 'Internet of things (IoT) Data – Blockchain Integration',
};

const dataAiData = {
	TITLE: 'DATA & AI, MACHINE LEARNING',
	P1: 'The quantum of information available at our disposal today is overwhelming, and exactly here, we have begun our journey. We sort through this mountain of data to find the insights that truly matter. We give our customers easy access to important Business Intelligence, future predictions, and useful recommendations.',
	P2: 'We offer a suite of services that bring together the power of data-driven insights and self-learning algorithms to create game-changing solutions for your business, that include –',
};

const dataAiServicesData = {
	SERVICES1: 'Data Analytic & Visualisation',
	SERVICE2: 'Predictive Analytics',
	SERVICE3: 'Natural Language Processing (NLP)',
	SERVICE4: 'Data Strategy Consulting',
	SERVICE5: 'AI/ML Model Development and Deployment',
};

const systemIntegrationData = {
	TITLE: 'SYSTEM INTEGRATION & PRODUCTIVITY SOLUTIONS',
	P1: 'In the era of endless applications and fragmented business tools, the importance of efficient system integrations and productivity solutions has never been greater. We like the challenge of bringing in our vast expertise in these areas to bring seamless interactions between your existing systems and enhance your workflows to drive more productivity and efficiency. Our custom solutions to unify your disjointed systems, automate your processes and bring about data-driven decisions that will bring coherence to your technology estate.',
};

const systemIntegrationServicesData = {
	TITLE: 'Our services include',
	SERVICE1: 'Mobile App development',
	SERVICE2: 'API Integrations',
	SERVICE3: 'Custom API developments',
	SERVICE4: 'Enterprise web development',
	SERVICE5: 'App Modernisation',
};

export {
	serivceTitle,
	blockchainData,
	blockchainServicesData,
	dataAiData,
	dataAiServicesData,
	systemIntegrationData,
	systemIntegrationServicesData,
};
