import React, { useState } from 'react';
import { gsap, Power3, TweenMax } from 'gsap';
import { useRef, useEffect } from 'react';
import '../Assets/Styles/Global.css';
import heroWeb from '../Assets/Images/hero_illustration_ 1.svg';
import heroMobile from '../Assets/Images/mobile_hero_illustration.svg';

import ant from '../Assets/Images/ant1_hero_illustration.svg';
import AboutUsIllustration from '../Assets/Images/about_us_.svg';
import aboutCraftIllustration from '../Assets/Images/how_We_go_about_crafts_illustration.svg';
import ideationIllustration from '../Assets/Images/ideation_illustration_withblurbg-01.svg';
import prototypeIllustration from '../Assets/Images/prototype-withblurbg.svg';
import devIllustration from '../Assets/Images/dev_withblurbg.svg';
import testingIllustration from '../Assets/Images/testing_withblurbg.svg';
import goLiveIllustration from '../Assets/Images/go_live-withblurbg.svg';
import ideationMobile from '../Assets/Images/ideation.svg';
import prototypeMobile from '../Assets/Images/prototypemobile.svg';
import devMobile from '../Assets/Images/dev 1.svg';
import testingMobile from '../Assets/Images/testing.svg';
import goLiveMobike from '../Assets/Images/goliveTiny.svg';
import ourTeamIllustration from '../Assets/Images/our_team.svg';
import cultureIllustration from '../Assets/Images/culture.svg';
import workTogetherIllustration from '../Assets/Images/work_together_.svg';
import lineIllustration from '../Assets/Images/Vector 654.svg';
import lineIllustration2 from '../Assets/Images/Vector 655.png';
import logosGif from '../Assets/Images/logos_animation.gif';
import documentLoadingComplete from '../Assets/Images/uploaded-successfully.svg';
import documnetUploading from '../Assets/Images/document-uploading.svg';
import loadingGif from '../Assets/Images/loading.gif';
import roundIllustration from '../Assets/Images/partner_round_illus.svg';

import quoteTop from '../Assets/Images/“.png';
import quoteBottom from '../Assets/Images/“-2.png';
import blurLeft from '../Assets/Images/blur-left.png';
import blueRight from '../Assets/Images/blurRight.png';
import techLogo from '../Assets/Images/tech_logos.svg';
import moralis from '../Assets/Images/Moralis-Logo-LightBG-Large.svg';
import fultter from '../Assets/Images/Google-flutter-logo.svg';
import near from '../Assets/Images/near.svg';
import googleCloud from '../Assets/Images/Google_Cloud_logo.svg';
import Azur from '../Assets/Images/Microsoft_Azure_Logo.svg';
import hyper from '../Assets/Images/Hyoer.svg';
import r3 from '../Assets/Images/r3.svg';
import whatsappLogo from '../Assets/Images/whatsapp.svg';
import emailLogo from '../Assets/Images/email.svg';
import modalIllus from '../Assets/Images/modal_illus.svg';
import profileImage from '../Assets/Images/profile_pic.svg';
import rightArrow from '../Assets/Images/rigthArrow.png';
import leftAeeow from '../Assets/Images/leftArrow.png';
import skilledCraftMen from '../Assets/Images/new_illustration.svg';
import reachUsIllus from '../Assets/Images/reach_us_illus.svg';

import '../Assets/Styles/Home.css';
import '../Assets/Styles/Global.css';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {
	aboutOurCraftData,
	aboutUsData,
	cultureData,
	formData,
	headerText,
	ourTeamData,
	techData,
	testimonialData,
	workTogetherData,
} from '../Assets/Utils/HomePageData';
import Footer from '../Assets/Components/Footer';
import Ticker from 'react-ticker';
import { storage } from '../Firestore/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { ICONS, testimonialDataobject } from '../Assets/Utils/assets';
import AboutUs from './AboutUs';

function Home({ elementRef }) {
	const [fileUpload, setFileUpload] = useState();
	const [popup, setPopup] = useState(false);
	const [send, setSend] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(0);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
	const currentTestimonial = testimonialDataobject[currentTestimonialIndex];

	const [testimonalImg, setTestimonailImg] = useState(
		currentTestimonial.companyLogo
	);

	const showNextTestimonial = () => {
		setCurrentTestimonialIndex(
			(prevIndex) => (prevIndex + 1) % testimonialDataobject.length
		);
	};

	// Function to show the previous testimonial
	const showPreviousTestimonial = () => {
		setCurrentTestimonialIndex(
			(prevIndex) =>
				(prevIndex - 1 + testimonialDataobject.length) %
				testimonialDataobject.length
		);
	};

	// Current testimonial

	console.log(error);

	const [resumeData, setResumeData] = useState({
		firstName: '',
		lastName: '',
		message: '',
	});

	console.log(resumeData);
	// const elementRef = useElementRef();

	const box1 = useRef(null);
	const box2 = useRef(null);
	const box3 = useRef(null);
	const box4 = useRef(null);
	const box5 = useRef(null);
	const box6 = useRef(null);
	const box7 = useRef(null);
	const box8 = useRef(null);
	const ourTeam = useRef(null);
	const culture = useRef(null);
	const workTogether = useRef(null);
	const testimonail = useRef(null);
	const testimonailText = useRef(null);
	const craftman = useRef(null);
	const craftman2 = useRef(null);
	const moralisAnime = useRef(null);
	const ideation = useRef(null);
	const prototype = useRef(null);
	const dev = useRef(null);
	const testing = useRef(null);
	const golive = useRef(null);

	gsap.registerPlugin(ScrollTrigger);

	const tl = gsap.timeline();

	const handelError = (e) => {
		if (resumeData.firstName.length === 0) {
			setError(1);
		} else if (resumeData.lastName.length === 0) {
			setError(2);
		} else if (resumeData.message.length === 0) {
			setError(3);
		} else if (!fileUpload) {
			setError(4);
		} else {
			setError(0);
			handelFile(e);
			setLoading(true);
		}
	};
	const sendResume = async (fileName, url) => {
		const mailData = {
			to: 'hr@antphy.com',
			from: 'datatestingdummy@gmail.com',
			subject: `${resumeData.firstName} ${resumeData.lastName} wants to work us `,
			filename: fileName,
			attachmentUrl: url,
			text: `Hi there, 

	
	${resumeData.firstName} ${resumeData.lastName} wants to work with us. 
	PFA their resume.

	They said:

	${resumeData.message}`,
		};
		try {
			const response = await axios
				.post(
					'https://us-central1-antphy-dev.cloudfunctions.net/app/send-mail',
					mailData
				)
				.then(() => {
					setLoading(false);
					setPopup(true);
					setFileUpload();
					setResumeData({
						firstName: '',
						lastName: '',
						message: '',
					});
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handelFile = async (e) => {
		e.preventDefault();
		if (fileUpload) {
			const storageRef = ref(storage, `/files/${fileUpload.name}`);
			const uploadTask = uploadBytesResumable(storageRef, fileUpload);
			// console.log(uploadTask);
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					const percent = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					);
				},
				(err) => console.log(err),
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then((url) => {
						console.log(url, 'url');
						sendResume(fileUpload.name, url);
					});
				}
			);
		}
	};

	const buttonClass = isButtonDisabled
		? 'para-white bg-bgBlack pt-3 pb-3 pr-10 pl-10 rounded-full save flex justify-center items-center'
		: 'para-white bg-bgBlack pt-3 pb-3 pr-10 pl-10 rounded-full save flex justify-center items-center';

	const rigthAnime = (container) => {
		// 15,000 milliseconds = 15 seconds

		// Clear the timer to prevent a memory leak

		tl.from(container.current, 2, {
			opacity: 0,
			x: '300',
			ease: Power3.easeInOut,
			scrollTrigger: {
				trigger: container.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
	};
	const leftAnime = (container) => {
		tl.from(container.current, 2, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeInOut,
			scrollTrigger: {
				trigger: container.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
	};

	const topAnime = (container) => {
		tl.from(container.current, 3, {
			opacity: 0,
			y: '-200',
			ease: Power3.easeInOut,
			scrollTrigger: {
				trigger: container.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
	};

	const [tickerInitialized, setTickerInitialized] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top when the component mounts

		const delayTicker = setTimeout(() => {
			setTickerInitialized(true);
		}, 5000);

		tl.from(box1.current, 2, { opacity: 0, x: '300', ease: Power3.easeOut });
		tl.from(
			box2.current,
			2,
			{
				opacity: 0,
				x: '-300',
				ease: Power3.easeOut,
			},
			'-=0.5'
		);

		tl.from(box3.current, 4, {
			opacity: 0,
			x: '-300',
			// duration: 4,
			ease: Power3.easeInOut,
			scrollTrigger: {
				trigger: box3.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});

		tl.from(craftman2.current, 10, {
			opacity: 0,
			y: '-250',
			ease: Power3.easeInOut,
			scrollTrigger: {
				trigger: craftman2.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		// tl.from(craftman2.current, 10, {
		// 	opacity: 0,
		// 	x: '-500',
		// 	// duration: 8,
		// 	ease: Power3.easeIn,
		// 	scrollTrigger: {
		// 		trigger: craftman2.current,
		// 		start: 'top 80%',
		// 		end: 'bottom 80%',
		// 		scrub: 1,
		// 	},
		// });

		// leftAnime(box3);
		leftAnime(box4);
		rigthAnime(box5);
		leftAnime(box6);
		rigthAnime(box7);
		leftAnime(box8);
		leftAnime(ideation);
		rigthAnime(prototype);
		leftAnime(dev);
		rigthAnime(testing);
		leftAnime(golive);
		topAnime(culture);
		rigthAnime(testimonail);
		rigthAnime(testimonailText);
		leftAnime(workTogether);
		leftAnime(craftman);
		topAnime(ourTeam);
		// topAnime(craftman2);
	}, []);

	return (
		<>
			<Helmet>
				<title>Ant Philosophy Technology and Services</title>
				<link rel="canonical" href="https://www.antphy.com/" />
				<meta
					name='description'
					content='Ant Philosophy is a product centric company that strives to own the way technology should work and be adopted for real world applications.'
				/>
			</Helmet>
		<div className=" bg-bgBlack overflow-hidden ">
			<div className="  w-screen relative ">
				<div>
					<div className=" flex justify-end items-center " ref={box1}>
						<div className="  h-screen manIllus">
							<div className=" flex h-full justify-end  items-end">
								<img srcSet="" src={heroWeb} alt="Hero Illustration" className="hero-illus" />
							</div>
							<div className=" border-b-[1px] border-textWhite"></div>
						</div>
					</div>
					<div
						className=" flex flex-col h-screen justify-center pl-20  hero-text "
						ref={box2}
					>
						<div className="">
							<img src={ant} alt="Ant" className="ant mb-[-24px] " />
						</div>

						<p className="hero-title title-white ">{headerText.H1}</p>
						<p className="hero-title title-white"> {headerText.H2}</p>
						<p className="hero-title title-white"> {headerText.H3}</p>
						<p className="hero-title title-white"> {headerText.H4}</p>
						{/* <p className=" para-white hero-sub">{headerText.SUBHEADING}</p> */}
					</div>
				</div>
			</div>
			{/* About */}
			<section id="about-us" ref={elementRef}>
				<AboutUs boxRef={box3} />
			</section>
			{/* About our craft   */}
			<div className=" ">
				<div className=" h-screen bg-blur p-20 flex justify-center items-center craft">
					<div
						ref={craftman2}
						className=" flex flex-col justify-center items-center"
					>
						<img
							src={skilledCraftMen}
							alt="Skilled Craftsmen"
							className=" 
							skillerd-img
							"
						/>

						<p className=" font-Exo2Regular text-textWhite text-cente   text-center">
							{aboutOurCraftData.TEXT}
						</p>
					</div>
				</div>

				<div className=" craft-main craft-desk">
					<div className="flex justify-center items-center flex-col">
						<div className="craft-illus">
							<img src={aboutCraftIllustration} alt="About Craft Illustration" />
						</div>
						<div>
							<p className=" text-text54 font-Exo2Regular text-textWhite pt-10 text-center craft-main-title">
								{aboutOurCraftData.TITLE}
							</p>
						</div>
						<div className=" pt-20 pb-20">
							<div className="how-main">
								<div className=" how ">
									<div ref={ideation} className="">
										<img
											src={ideationIllustration}
											alt="Ideation Illustration"
											className="mr-40"
											width={447.257}
											height={534.098}
										/>
									</div>
									<img src={lineIllustration} alt="Line Illustration" className="" />
								</div>
								<div>
									<p className="craft-title">IDEATION</p>
									<p className="craft-subtitle">Eureka Moment!</p>
								</div>
							</div>

							<div className="flex justify-center items-center">
								<div>
									<p className="craft-title">PROTOTYPING</p>
									<p className="craft-subtitle">Mad Scientists </p>
								</div>
								<div className=" how">
									<div iv ref={prototype} className="craft-illus-left">
										<img
											src={prototypeIllustration}
											alt="Prototype Illustration"
											className=""
											width={447.257}
											height={564.098}
										/>
									</div>

									<img src={lineIllustration2} alt="Line Illustration" className=" " />
								</div>
							</div>

							<div className="flex justify-center items-center">
								<div className="how">
									<div ref={dev} className="craft-illus-right">
										<img
											src={devIllustration}
											alt="Dev Illustration"
											className=""
											width={447.257}
											height={564.098}
										/>
									</div>
									<img src={lineIllustration} alt="Line Illustration" className="" />
								</div>

								<div className="test">
									<p className="craft-title">DEV</p>
									<p className="craft-subtitle">Nerd Nirvana</p>
								</div>
							</div>

							<div className="flex justify-center items-center">
								<div>
									<p className="craft-title">TESTING</p>
									<p className="craft-subtitle">Baking</p>
								</div>
								<div className="how">
									<div ref={testing} className="craft-illus-left">
										<img
											src={testingIllustration}
											alt="Testing Illustration"
											width={447.257}
											height={564.098}
										/>
									</div>
									<img src={lineIllustration2} alt="Line Illustration" className=" " />
								</div>
							</div>

							<div className="flex justify-center items-center">
								<div>
									<div
										ref={golive}
										className="craft-illus-right "
										width={447.257}
										height={564.098}
									>
										<img src={goLiveIllustration} alt="Go Live Illustration" className="" />
									</div>
								</div>

								<div>
									<p className="craft-title">GO LIVE</p>
									<p className="craft-subtitle">Show Time</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* mobile */}
				<div className=" craft-main craft-mobile">
					<div className="flex justify-center items-center flex-col">
						<div className="craft-illus">
							<img src={aboutCraftIllustration} alt="About Craft Illustration" />
						</div>
						<div>
							<p className=" text-text54 font-Exo2Regular text-textWhite pt-10 text-center craft-main-title">
								{aboutOurCraftData.TITLE}
							</p>
						</div>
					</div>
					<div className=" pt-20 pb-20">
						<div className="how-main">
							<div>
								<div className=" flex justify-between items-end">
									<div className="img" ref={box4}>
										<img src={ideationMobile} alt="Ideation Mobile" width={276} height={337} />
									</div>
									<div>
										<p className="craft-title">IDEATION</p>
										<p className="craft-subtitle">Eureka Moment!</p>
									</div>
								</div>
								<div>
									<img src={lineIllustration} alt="Line Illustration" className="" />
								</div>
							</div>
							<div>
								<div className=" flex justify-between items-end">
									<div>
										<p className="craft-title">PROTOTYPING</p>
										<p className="craft-subtitle">Mad Scientists</p>
									</div>
									<div className="mr-[-40px] " ref={box5}>
										<img
											src={prototypeMobile}
											alt="Prototype Mobile"
											width={276}
											height={337}
										/>
									</div>
								</div>
								<div className="flex justify-end items-end">
									<img src={lineIllustration2} alt="Line Illustration" className="" />
								</div>
							</div>
							<div>
								<div className=" flex justify-between items-end">
									<div className="img" ref={box6}>
										<img src={devMobile} alt="Dev Mobile" width={276} height={337} />
									</div>
									<div>
										<p className="craft-title">DEV</p>
										<p className="craft-subtitle">Nerd Nirvana </p>
									</div>
								</div>
								<div>
									<img src={lineIllustration} alt="Line Illustration" className="" />
								</div>
							</div>
							<div>
								<div className=" flex justify-between items-end">
									<div>
										<p className="craft-title">TESTING</p>
										<p className="craft-subtitle">Baking</p>
									</div>
									<div className="mr-[-40px]" ref={box7}>
										<img src={testingMobile} alt="Testing Mobile" width={276} height={337} />
									</div>
								</div>
								<div className="flex justify-end items-end">
									<img src={lineIllustration2} alt="Line Illustration" className="" />
								</div>
							</div>
							<div>
								<div className=" flex justify-between items-end">
									<div className="img" ref={box8}>
										<img src={goLiveMobike} alt="Go Live Mobile" width={276} height={337} />
									</div>
									<div>
										<p className="craft-title">GO LIVE</p>
										<p className="craft-subtitle">Show Time</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Our Team */}
			<div className="outTeam">
				<div
					ref={ourTeam}
					className="flex flex-col justify-center items-center"
				>
					<img
						src={ourTeamIllustration}
						alt="Our Team Illustration"
						width={404.079}
						height={297.738}
					/>
				</div>
				<div className="ourTeam-content">
					<p className=" title-black title-padding">{ourTeamData.TITLE}</p>
					<p className="para-black">{ourTeamData.P1}</p>
					<p className="para-black body-padding ">{ourTeamData.P2}</p>
				</div>
			</div>
			{/* culture */}
			<div className=" culture ">
				<div className=" flex justify-centre items-center flex-col">
					<img
						src={cultureIllustration}
						alt="Culture Illustration"
						className="bg-blur"
						ref={culture}
						width={700}
					/>
					<div className="culture-content  culture-text  ">
						<p className=" title-black title-padding ">{cultureData.TITLE}</p>
						<p className=" para-black ">{cultureData.P1}</p>
						<p className=" para-black  body-padding">{cultureData.P2}</p>
					</div>
				</div>
			</div>
			{/* Tech we work with */}
			<div className=" tech ">
				<div className="">
					<p className=" title-white">{techData.TITLE}</p>
				</div>
				<div className=" pt-20 w-full h-full  ">
					<div className="tech2">
						<div className="container">
							{/* <div>
								<video src="../Assets/Images/logos3.mov"></video>
							</div> */}
							{/* <div>
								<img src={logosGif} alt="" />
							</div> */}
							<marquee scrollamount="3.5">
								<div className=" flex justify-center items-center gap-16">
									<img src={moralis} alt="Moralis Logo" />
									<img src={googleCloud} alt="Google Cloud Logo" />
									<img src={Azur} alt="Microsoft Azure Logo" />
									<img src={hyper} alt="Hyperledger Logo" />
									<img src={r3} alt="R3 Corda Logo" />
									<img src={fultter} alt="Google Flutter Logo" />
									<img src={near} alt="NEAR Blockchain Logo" />
									<img src={moralis} alt="Moralis Logo" />
									<img src={googleCloud} alt="Google Cloud Logo" />
									<img src={Azur} alt="Microsoft Azure Logo" />
									<img src={hyper} alt="Hyperledger Logo" />
									<img src={r3} alt="R3 Corda Logo" />
									<img src={fultter} alt="Google Flutter Logo" />
									<img src={near} alt="NEAR Blockchain Logo" />
									<img src={moralis} alt="Moralis Logo" />
									<img src={googleCloud} alt="Google Cloud Logo" />
									<img src={Azur} alt="Microsoft Azure Logo" />
									<img src={hyper} alt="Hyperledger Logo" />
									<img src={r3} alt="R3 Corda Logo" />
									<img src={fultter} alt="Google Flutter Logo" />
									<img src={near} alt="NEAR Blockchain Logo" />
									<img src={moralis} alt="Moralis Logo" />
									<img src={googleCloud} alt="Google Cloud Logo" />
									<img src={Azur} alt="Microsoft Azure Logo" />
									<img src={hyper} alt="Hyperledger Logo" />
									<img src={r3} alt="R3 Corda Logo" />
									<img src={fultter} alt="Google Flutter Logo" />
									<img src={near} alt="NEAR Blockchain Logo" />
								</div>
							</marquee>
							{/* <div> */}
							{/* Your other components */}
							{/* {tickerInitialized ? (
									<Ticker> */}
							{/* {({ index }) => (
											<div className=" flex justify-center items-center gap-14">
												<img src={moralis} alt="" />
												<img src={googleCloud} alt="" />
												<img src={Azur} alt="" />
												<img src={hyper} alt="" />
												<img src={r3} alt="" className="pr-14" />
											</div>
										)}
									</Ticker>
								) : (
									<div className=" flex justify-center items-center gap-14">
										<img src={moralis} alt="" />
										<img src={googleCloud} alt="" />
										<img src={Azur} alt="" />
										<img src={hyper} alt="" />
										<img src={r3} alt="" className="pr-14" />
									</div>
								)}
							</div> */}

							{/* <div class="logs">
								<div class="logs-slide">
									<img src={moralis} />
									<img src={Azur} />
									<img src={hyper} />
									<img src={googleCloud} />
									<img src={r3} />
								</div>
								<div class="logs-slide">
									<img src={moralis} />
									<img src={Azur} />
									<img src={hyper} />
									<img src={googleCloud} />
									<img src={r3} />
								</div>
								<div class="logs-slide">
									<img src={moralis} />
									<img src={Azur} />
									<img src={hyper} />
									<img src={googleCloud} />
									<img src={r3} />
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			{/* Testimonails */}

			{/* <div className=" w-full p-20 testimonial relative  ">
				<div className=" w-3/5" ref={testimonail}>
					<p className="title-white ">{testimonialData.TITLE}</p>
				</div>
				<div className="bg-blur-blue p-20 w-[1350px] h[500px]">
					<div className=" w-full  justify-center items-center   ">
						<div className="  flex justify-between items-center w-[80%] h-1/2  absolute ml-[-10px] pb-20">
							<button onClick={showPreviousTestimonial}>
								<img src={leftAeeow} alt="" width={50} height={50} />
							</button>
							<button onClick={showNextTestimonial}>
								<img src={rightArrow} alt="" width={50} height={50} />
							</button>
						</div>
					</div>
					<div className=" flex  items-center">
						<img
							src={currentTestimonial.companyLogo}
							alt="company-logo"
							className=" pr-2"
							width={50}
							height={50}
						/>
						<img
							src={currentTestimonial.companyLogoText}
							alt="company-name"
							width={50}
							height={50}
						/>
					</div>
					<div className=" pt-10 px-10">
						<div className=" flex justify-start items-center">
							<img src={quoteTop} alt="" />
						</div>
						<div
							className=" flex justify-center items-center"
							// ref={testimonailText}
						>
							<p className=" para-white w-3/4 text-center">
								{currentTestimonial.content}
							</p>
						</div>
						<div className=" flex justify-end items-center">
							<img src={quoteBottom} alt="" />
						</div>
					</div>
					<div className=" flex justify-end mt-10 ml-10">
						<img src={profileImage} alt="" className=" pr-5" />
						<div className=" para-white">
							<p>{currentTestimonial.fullName}</p>
							<div className=" border-1 , border-[#F6F5EF]"></div>
							<p>{currentTestimonial.companyName}</p>
						</div>
					</div>
				</div>
			</div> */}

			{/* Worktogether */}
			<div className=" work-together bg-blur">
				<div ref={workTogether}>
					<img
						src={workTogetherIllustration}
						alt="Work Together Illustration"
						width={564.121}
						height={284.479}
					/>
				</div>
				<div className=" flex flex-col justify-center items-center">
					<p className=" title-white  title-padding text-center">
						{workTogetherData.TITLE}
					</p>
					<p className="para-white w-3/4 text-center">{workTogetherData.P1}</p>
					<p className=" para-blue mt-5">We will get back to you</p>
				</div>
			</div>

			{/* Form  */}
			<div className=" w-full pb-40 bg-blur bg-blur" id="careers-form">
				{/* <div className=" form-bg">
					<div className=" form-content">
						<div className=" input-div">
							<label htmlFor="firstName" className="lable-black">
								{formData.FIRST_NAME}
							</label>
							<input
								type="text"
								id="firstName"
								value={resumeData.firstName}
								required
								className="input"
								onChange={(e) => {
									setResumeData({
										...resumeData,
										firstName: e.target.value,
									});
								}}
							/>
							{error === 1 ? (
								<p className=" error ">** Enter First Name **</p>
							) : null}
						</div>
						<div className=" input-div">
							<label htmlFor="lastName" className="lable-black">
								{formData.LAST_NAME}
							</label>
							<input
								type="text"
								id="lastName"
								value={resumeData.lastName}
								required
								className="input"
								onChange={(e) => {
									setResumeData({
										...resumeData,
										lastName: e.target.value,
									});
								}}
							/>
							{error === 2 ? (
								<p className="error ">** Enter Last Name **</p>
							) : null}
						</div>

						<div className="input-div">
							<label htmlFor="message" className="lable-black">
								{formData.MESSAGE}
							</label>
							<textarea
								id="message"
								className="textarea"
								value={resumeData.message}
								onChange={(e) => {
									setResumeData({
										...resumeData,
										message: e.target.value,
									});
								}}
							/>
							{error === 3 ? (
								<p className="error ">** Enter Message **</p>
							) : null}
						</div>
						<div className=" flex justify-start items-center">
							<div className="custom-upload-button">
								{!fileUpload ? (
									<div>
										<label
											htmlFor="resumeInput"
											className="para-white bg-bgBlack pt-3 pb-3 pr-10 pl-10 rounded-full cursor-pointer"
										>
											UPLOAD RESUME
										</label>
										<input
											type="file"
											accept=".pdf"
											multiple={false}
											name=""
											id="resumeInput"
											style={{ display: 'none' }}
											onChange={(e) => {
												// console.log(e.target.files[0]);
												setFileUpload(e.target.files[0]);
												setSend(true);
											}}
										/>
									</div>
								) : (
									<button
										onClick={() => {
											// handelFile();
											handelError();
										}}
										className="para-white bg-bgBlack pt-3 pb-3 pr-10 pl-10 rounded-full save flex justify-center items-center"
									>
										<p>Send</p>
									</button>
								)}
							</div>
						</div>
						<div className=" mt-24">
							<p className="reachUs-title">OR REACH US:</p>
							<div className=" flex justify-start items-center pt-5">
								<img src={whatsappLogo} alt="" />
								<a
									href="https://wa.me/+918660374515"
									target="_blank"
									className="reachUs-subtitle pl-2"
								>
									8660374515
								</a>
							</div>
							<div className=" flex justify-start items-center pt-2">
								<img src={emailLogo} alt="" />
								<p className="reachUs-subtitle pl-2">
									<a href="mailto:hr@antphy.com" target="_blank">
										hr@antphy.com
									</a>
								</p>
							</div>
						</div>
					</div>
				</div> */}
				<div className=" my-5 bg-lightBlue p-10   bg-blur font-Exo2Regular slanted-bg ">
					<div className="form-slant-content-reachUs ">
						<div className=" form-illus">
							<img
								src={reachUsIllus}
								alt="Reach Us Illustration"
								className="reach-us-illus"
								width={350}
								height={350}
							/>
							<div className="reach-us-content">
								<p className="form-black ">OR REACH US:</p>
								<div className="reach-us-text">
									<div className=" flex justify-start items-center pt-3">
										<img
											src={whatsappLogo}
											alt="WhatsApp Logo"
											width={18}
											height={18}
											className="icon"
										/>
										<a
											aria-label='AntPhy | WhatsApp'
											href="https://wa.me/+918660374515"
											target="_blank"
											className=" pl-2 reachUs-title"
										>
											8660374515
										</a>
									</div>
									<div className=" flex justify-start items-center pt-2">
										<img
											src={emailLogo}
											alt="Email Logo"
											width={18}
											height={18}
											className="icon"
										/>
										<p className=" pl-2 reachUs-title">
											<a href="mailto:hr@antphy.com" aria-label='AntPhy | Email' target="_blank">
												hr@antphy.com
											</a>
										</p>

										{/* <a href="mailto:info@antphilosophy.com">
									info@antphilosophy.com
								</a> */}
									</div>
								</div>
							</div>
						</div>
						<form className=" bg-bgBlack form font-Exo2Regular    ">
							<div className=" flex flex-col ">
								<label htmlFor="firstName" className=" form-white">
									{formData.FIRST_NAME}
								</label>
								<input
									type="text"
									value={resumeData.firstName}
									required
									placeholder="First Name"
									className=" bg-transparent border-2 border-white500 p-3 mt-3 rounded-full form-white"
									onChange={(e) => {
										setResumeData({
											...resumeData,
											firstName: e.target.value,
										});
									}}
								/>
								{error === 1 ? (
									<p className=" error ">** Enter First Name **</p>
								) : null}
							</div>
							<div className=" flex flex-col mt-5">
								<label htmlFor="lastName" className=" form-white">
									{formData.LAST_NAME}
								</label>
								<input
									type="text"
									value={resumeData.lastName}
									placeholder="Last Name"
									className=" bg-transparent border-2 border-white500 p-3 mt-3 rounded-full form-white"
									onChange={(e) => {
										setResumeData({
											...resumeData,
											lastName: e.target.value,
										});
									}}
								/>
								{error === 2 ? (
									<p className="error ">** Enter Last Name **</p>
								) : null}
							</div>
							<div className=" flex flex-col  mt-5 ">
								<label htmlFor="firstName" className=" form-white">
									{formData.MESSAGE}
								</label>
								<textarea
									id="message"
									value={resumeData.message}
									className=" bg-transparent border-2 border-white500 p-3 mt-3 rounded-xl h-[150px] form-white"
									onChange={(e) => {
										setResumeData({
											...resumeData,
											message: e.target.value,
										});
									}}
								/>
								{error === 3 ? (
									<p className="error ">** Enter Message **</p>
								) : null}
							</div>
							<div className=" flex justify-start items-center mt-8">
								{/* <button className=" bg-lightBlue text-bgBlack px-20 py-3 flex justify-center items-center rounded-full">
									<p>UPLOAD RESUME</p>
								</button> */}
								{!fileUpload ? (
									<div>
										<label htmlFor="resumeInput" className="blue-btn">
											UPLOAD RESUME
										</label>
										<input
											type="file"
											accept=".pdf"
											multiple={false}
											name=""
											id="resumeInput"
											style={{ display: 'none' }}
											onChange={(e) => {
												// console.log(e.target.files[0]);
												setFileUpload(e.target.files[0]);
												setSend(true);
											}}
										/>
									</div>
								) : (
									<button
										onClick={(e) => {
											// handelFile();
											handelError(e);
										}}
										className="blue-btn"
									>
										<p>Send</p>
									</button>
								)}
							</div>
						</form>
					</div>
				</div>
			</div>
			{popup && (
				<div className="modal ">
					{/* <p>Hello</p> */}
					<div className="modal-content  ">
						<div>
							<img src={modalIllus} alt="Modal Illustration" width={200} height={200} />
						</div>
						<div className=" para-black pt-5">
							<p>Thank you for contacting us!</p>
							<p>We will contact you soon.</p>
						</div>
						<button
							className="submit-btn mt-10"
							onClick={() => {
								setPopup(false);
								setIsButtonDisabled(false);
							}}
						>
							<p className="para-white">CLOSE</p>
						</button>
					</div>
				</div>
			)}
			{loading && (
				<div className="modal ">
					{/* <p>Hello</p> */}
					<div className=" flex justify-center items-center flex-col">
						<div className=" flex flex-col justify-center items-center">
							<img src={documnetUploading} alt="Uploading animation" />
							<p className=" para-white">Please wait</p>
							<p className="loading-blue">Your resume is uploading</p>
						</div>
						<div className=" flex flex-col justify-center items-center pt-20">
							<img src={loadingGif} alt="Loading" width={150} />
						</div>
					</div>
				</div>
			)}
			{/* <Footer /> */}
			</div>
			</>
	);
}

export default Home;
