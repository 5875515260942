import React, { useEffect, useRef } from 'react';
import {
	systemIntegrationData,
	systemIntegrationServicesData,
} from '../Assets/Utils/ServicePageData';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap, Power3 } from 'gsap';
import systemIllustartion from '../Assets/Images/systemIllustartion.png';
import apiIllustration from '../Assets/Images/api_illus.svg';
import appModernisation from '../Assets/Images/app_modernisation_ilus.svg';
import customApi from '../Assets/Images/custom_api_illus.svg';
import enterpriseWebDev from '../Assets/Images/enterprise_web_dev_illus.svg';

import mobileAppDev from '../Assets/Images/mobile_app_dev.svg';
import { Helmet } from 'react-helmet-async';

export default function SystemIntegration() {
	const three = useRef(null);
	const systemTitle = useRef(null);
	const systemIllus = useRef(null);
	const system1 = useRef(null);
	const system2 = useRef(null);
	const system3 = useRef(null);
	const system4 = useRef(null);
	const system5 = useRef(null);

	gsap.registerPlugin(ScrollTrigger);
	const tl = gsap.timeline();
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top when the component mounts

		tl.from(three.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: three.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(systemTitle.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: systemTitle.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(systemIllus.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: systemIllus.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(system1.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: system1.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(system2.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: system2.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(system3.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: system3.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(system4.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: system4.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(system5.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: system5.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
	}, []);
	return (
		<>
			<Helmet>
				<title>System Integration</title>
				<link rel="canonical" href="https://www.antphy.com/system-integrations" />
				<meta
					name='description'
					content='In the era of endless applications and fragmented business tools, the importance of efficient system integrations and productivity solutions has never been greater. We like the challenge of bringing in our vast expertise in these areas to bring seamless interactions between your existing systems and enhance your workflows to drive more productivity and efficiency. Our custom solutions to unify your disjointed systems, automate your processes and bring about data-driven decisions that will bring coherence to your technology estate.'
				/>
			</Helmet>
		<div
			className=" flex flex-col justify-center items-center w-full pb-20 bg-bgBlack mt-20"
			id="system-integraions"
		>
			<div
				className=" flex flex-col justify-center items-center pt-40 bg-blur"
				ref={systemIllus}
			>
				<img src={systemIllustartion} alt="System Illustration" width={517.314} height={339.508} />
			</div>

			<div className=" flex justify-center items-center">
				<p className=" num mt-10" ref={three}>
					03
				</p>
			</div>
			<div className="system-integration  pt-5 pb-10">
				<p className="title-white  text-center ">
					{systemIntegrationData.TITLE}
				</p>
			</div>
			<div className=" w-3/4 pb-10 pt-1	0">
				<p className=" para-white text-center">{systemIntegrationData.P1}</p>
			</div>
			{/*  system integration services */}
			<div className=" flex flex-col justify-center items-center  w-full">
				<p className="para-blue">{systemIntegrationServicesData.TITLE}</p>
				<div className=" flex flex-col justify-center items-center w-full mt-10">
					<div className="service mt-5 relative" ref={system1}>
						<img
							src={mobileAppDev}
							alt="Mobile App Dev"
							className=" service-illus pr-5 pl-5"
						/>
						<p className=" para-white ">
							{systemIntegrationServicesData.SERVICE1}
						</p>
					</div>
					<div className="service mt-5 relative" ref={system2}>
						<img
							src={apiIllustration}
							alt="API Illustration"
							className=" service-illus pr-5 pl-5"
						/>
						<p className=" para-white ">
							{systemIntegrationServicesData.SERVICE2}
						</p>
					</div>
					<div className="service mt-5 relative" ref={system3}>
						<img src={customApi} alt="Custom API" className=" service-illus pr-5 pl-5" />
						<p className=" para-white ">
							{systemIntegrationServicesData.SERVICE3}
						</p>
					</div>
					<div className="service mt-5 relative" ref={system4}>
						<img
							src={enterpriseWebDev}
							alt="Enterprise Web Dev"
							className=" service-illus pr-5 pl-5"
						/>
						<p className=" para-white ">
							{systemIntegrationServicesData.SERVICE4}
						</p>
					</div>
					<div className="service mt-5 relative" ref={system5}>
						<img
							src={appModernisation}
							alt="App Modernisation"
							className=" service-illus pr-5 pl-5"
						/>
						<p className=" para-white ">
							{systemIntegrationServicesData.SERVICE5}
						</p>
					</div>
				</div>
			</div>
			</div>
			</>
	);
}
