import React, { useEffect, useRef } from 'react';
import { gsap, Power3 } from 'gsap';
// import '../Assets/Styles/Global.css';
import '../Assets/Styles/Services.css';
import servicesIllustration from '../Assets/Images/services4x.png';

import smartContract from '../Assets/Images/smart_supply_chain.svg';
import deFi from '../Assets/Images/DeFi.svg';
import digitalWallet from '../Assets/Images/digital_wallet.svg';
import identityAuth from '../Assets/Images/identity_authentication.svg';

import iotIllus from '../Assets/Images/iot.svg';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import whatsappLogo from '../Assets/Images/whatsapp.svg';
import emailLogo from '../Assets/Images/email.svg';

import {
	blockchainData,
	blockchainServicesData,
	dataAiData,
	dataAiServicesData,
	serivceTitle,
	systemIntegrationData,
	systemIntegrationServicesData,
} from '../Assets/Utils/ServicePageData';

import Blockchain from './Blockchain';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function Services() {
	const servicesIllus = useRef(null);
	const servicesTitle = useRef(null);
	const one = useRef(null);
	const blockchain = useRef(null);
	const blockchainIllus = useRef(null);
	const blockchain1 = useRef(null);
	const blockchain2 = useRef(null);
	const blockchain3 = useRef(null);
	const blockchain4 = useRef(null);
	const blockchain5 = useRef(null);

	const three = useRef(null);
	const systemTitle = useRef(null);
	const systemIllus = useRef(null);
	const system1 = useRef(null);
	const system2 = useRef(null);
	const system3 = useRef(null);
	const system4 = useRef(null);
	const system5 = useRef(null);
	gsap.registerPlugin(ScrollTrigger);
	const tl = gsap.timeline();
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top when the component mounts

		tl.from(servicesIllus.current, 4, {
			opacity: 0,
			y: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: servicesIllus.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(servicesTitle.current, 4, {
			opacity: 0,
			y: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: servicesTitle.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});

		tl.from(one.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: one.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchainIllus.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchainIllus.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain1.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain1.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain2.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain2.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain3.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain3.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain4.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain4.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(blockchain5.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: blockchain5.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
	}, []);
	return (
		<>
			<Helmet>
				<title>Services</title>
				<link rel="canonical" href="https://www.antphy.com/services" />
				<meta
					name='description'
					content='We help business to scale and transform through our services.'
				/>
			</Helmet>
		<div className=" bg-bgBlack h-auto overflow-hidden">
			{/* <div>Services</div> */}
			{/* servives hero */}
			<div className="bg-blur">
				<div className="services-illus" ref={servicesIllus}>
					<img src={servicesIllustration} alt="Services Illustration" width={397} height={355.108} />
				</div>
				<div className="flex justify-center items-center ">
					<div className="services-title " ref={servicesTitle}>
						<p className="title-white text-center pt-10 pb-10">
							{serivceTitle.TITLE}
						</p>
					</div>
				</div>
				<div className=" flex justify-center items-center flex-col pt-6">
					<p className=" para-blue ">Our Services</p>
					<div className="   pb-10 services">
						<Link class="dot relative " to="/blockchain">
							<p className="number">01</p>
							<p className=" absolute name">Blockchain</p>
						</Link>
						<Link class="dot relative" to="/data-ai">
							<p className="number">02</p>
							<p className=" absolute name">Data & Ai</p>
						</Link>
						<Link class="dot relative " to="/system-integrations">
							<p className="number">03</p>
							<p className=" absolute name">System Integrations</p>
						</Link>
					</div>
				</div>
			</div>

			{/* 01 - blockchain */}

			{/* 02  data and ai */}

			{/* 03 system integrstion  */}
			</div>
		</>
	);
}

export default Services;
