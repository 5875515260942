const navBarData = {
	HOME: 'HOME',
	ABOUT_US: 'ABOUT US',
	SERVICES: 'SERVICES',
	INDUSTRY: 'INDUSTRY',
	PRODUCTS: 'PRODUCTS',
	CONTACT_US: 'CONTACT US',
};

const headerText = {
	H1: 'CRAFTING IMPECCABLE',
	H2: 'EXPERIENCE; ENGINEERING',
	H3: 'TRANSFORMATIONAL',
	H4: 'BUSINESS IDEAS',
};

const aboutUsData = {
	TITLE: 'ABOUT US',
	P1: 'Ant Philosophy is a product centric company that strives to own the way technology should work and be adopted for real world applications. ',
	P2: 'We build products for customers and have set forth to build our own, in the areas of Blockchain & Distributed Ledger Technology (DLT) driven by Smart Contracts, Secure Communications, Digital Identity & Credentialing, Data & AI, Machine Learning and IoT. Our products are currently in Stealth mode.',
	P3: 'Stay tuned for updates.',
};

const aboutOurCraftData = {
	TEXT: `WE'RE THE SKILLED CRAFTMAN IN A DIGITAL WORKSHOP, METICULOUSLY SHAPING  PRODUCT IDEAS INTO TANGIBLE TECHONOLOGICAL INNOVATIONS`,
	TITLE: 'HOW WE GO ABOUT OUR CRAFT',
};

const ourTeamData = {
	TITLE: 'OUR TEAM',
	P1: 'Ant Philosophy was founded in 2022 with unrelenting passion and aim to build products that brings about an impact in the world we live in today, through technology.',
	P2: 'Our lean, proficient team believes in doing more with less. The flexibility and agility of our team helps pivot quickly, adapt to the requirements and accelerate to go-to-market. Each team member brings about a unique set of skills and a relentless drive to give their best.',
};

const cultureData = {
	TITLE: 'CULTURE',
	P1: 'We strive to build a place where eager minds collaborate in their common passion towards technology, where we are not bound by a rigid hierarchical structure. We have set ourselves a very high standard in innovation and staying ahead in this ever expanding information economy, and hence we stay invested in promoting continuous learning and skill-up, to stay abreast.',
	P2: 'It’s not just work but enjoying the journey along the way too!',
};

const techData = {
	TITLE: 'TECH WE WORK WITH',
};

const testimonialData = {
	TITLE: 'WHAT OUR CUSTOMERS WANT TO SAY',
};
const workTogetherData = {
	TITLE: 'LET’S WORK TOGETHER!',
	P1: 'If you are a professional with a skillsets that align with what we do and would like to be a part of our awesome team, please send us your resume.',
};

const formData = {
	TITLE: 'Thank you for reaching out.',
	SUBTITLE: 'We will contact you soon',
	FIRST_NAME: 'FIRST NAME',
	LAST_NAME: 'LAST NAME',
	COMPANY_NAME: 'COMPANY NAME',
	COMPANY_SIZE: 'COMPANY SIZE',
	HOW_CAN_WE_HELP: 'HOW CAN WE HELP?',
	MESSAGE: 'GIVE US A BRIEF ABOUT YOU',
};
export {
	navBarData,
	headerText,
	aboutUsData,
	aboutOurCraftData,
	ourTeamData,
	cultureData,
	techData,
	workTogetherData,
	formData,
	testimonialData,
};
