import React from 'react';
import footerLogo from '../Images/footer_logo.svg';
import footerIllustartion from '../Images/follow_us_on_.svg';
import instaLogo from '../Images/instagram.svg';
import linkdinLogo from '../Images/linkedin.svg';
import twitterLogo from '../Images/twitter.svg';
import reservedLogo from '../Images/rights_reserved.svg';
import facebookLogo from '../Images/facebook.svg';
import '../Styles/Footer.css';
import { HashLink } from 'react-router-hash-link';
import { Link, useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import whatsappLogo from '../Images/whatsapp.svg';
import emailLogo from '../Images/email.svg';
import addressLogoWeb from '../Images/address_icon_web.svg'
import addressLogoMobile from '../Images/address_icon_mobile.svg'

function Footer() {
	const navigate = useNavigate();
	const scrollTop = () => {
		scroll.scrollToTop();
	};

	const handleAboutUsClick = (e) => {
		e.preventDefault();
		const isHomePage = window.location.pathname === '/';
		
		navigate('/about-us');
		setTimeout(() => {
			scrollToAbout('about-us');
		}, 500);

		// if (isHomePage) {
		// 	scrollToAbout('about-us');
		// } else {
		// 	navigate('/');
		// 	setTimeout(() => {
		// 		scrollToAbout('about-us');
		// 	}, 500);
		// }
	};

	const handelJoinUs = (e) => {
		e.preventDefault();
		const isHomePage = window.location.pathname === '/';

		if (isHomePage) {
			scrollToAbout('careers-form');
		} else {
			navigate('/');
			setTimeout(() => {
				scrollToAbout('careers-form');
			}, 500);
		}
	};

	const handleServiceClick = (sectionId) => {
		// e.preventDefault();

		const isServicePage = window.location.pathname === '/services';

		if (isServicePage) {
			scrollToAbout(sectionId);
		} else {
			navigate('/services');
			setTimeout(() => {
				scrollToAbout(sectionId);
			}, 500);
		}
	};

	const scrollToAbout = (sectionId) => {
		const section = document.getElementById(sectionId);
		if (section) {
			window.scrollTo({
				top: section.offsetTop,
				behavior: 'smooth',
			});
		}
		// const isHomePage = window.location.pathname === '/home';

		// const currentPath = isHomePage ? '/home' : window.location.pathname;
		// const newUrl = `${currentPath}/${sectionId}`;
		// navigate(newUrl);
	};

	return (
		<div>
			{/* Footer */}

			<div className=" footer ">
				<div className="flex justify-between items-center footer-content desk ">
					<div>
						<Link to="/" onClick={scrollTop}>
							<img src={footerLogo} alt="Footer Logo" />
						</Link>
					</div>
					{/* <div>
						<p>Ant Philosophy Technology and Services Pvt. Ltd.</p>
						<p>B-301, SLS Spring Woods</p>
						<p>Silver County Road Off- Haralur Main Road</p>
						<p>Bengaluru Karnataka 560102 India</p>
					</div> */}
					<div>
						<div className=" flex justify-start items-start">
							<div className="pt-5">
								<p className="table-title"> COMPANY</p>
								<a href="" aria-label='About Us' smooth onClick={handleAboutUsClick}>
									<p className="table-data">ABOUT US</p>
								</a>
								<a href="" aria-label='Join Us' smooth onClick={handelJoinUs}>
									<p className="table-data">JOIN US</p>
								</a>
							</div>
							<div className="pt-5">
								<Link to="/products">
									<p className="table-title"> PRODUCTS</p>
								</Link>
								<Link to="/services">
									<p className="table-data">SERVICES</p>
								</Link>
								<Link to="/industry">
									<p className="table-data">INDUSTRY</p>
								</Link>
							</div>
							<div className="pt-5">
								<Link to="/contact-us">
									<p className="table-title"> CONTACT US</p>
								</Link>
								<div className=" flex justify-start items-start flex-col mb-5 pl-5">
									<div className=" flex">
										<img src={whatsappLogo} alt="WhatsApp Logo" width={15} height={15} />
										<a
											href="https://wa.me/+918660374515"
											target="_blank"
											className="table-data"
											aria-label='AntPhy | WhatsApp'
										>
											8660374515
										</a>
									</div>

									<div className=" flex">
										<img src={emailLogo} alt="Email Logo" width={15} height={15} />
										<a
											href="mailto:reachus@antphy.com"
											target="_blank"
											className="table-data"
											aria-label='AntPhy | Email'
										>
											reachus@antphy.com
										</a>{' '}
									</div>
								</div>
							</div>


							<div className="pt-5">
								<Link to="/contact-us">
									<p className="table-title">REGD ADDRESS</p>
								</Link>
								<div className=" flex justify-start items-start flex-col mb-5 pl-5">
									<div className="flex align-baseline">
										<img src={addressLogoWeb} alt="Address Icon" width={15} height={15} />
										<div>
											<p className='address-text'>Ant Philosophy Technology and Services Pvt. Ltd.</p>
											<p className='address-text'>B-301, SLS Spring Woods</p>
											<p className='address-text'>Silver County Road Off- Haralur Main Road</p>
											<p className='address-text'>Bengaluru Karnataka 560102 India</p>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
					<div className="">
						<div>
							<img src={footerIllustartion} alt="Footer Illustration" width={200} />
						</div>
						<div className=" flex justify-center items-center pt-5 flex-row">
							{/* <img src={instaLogo} alt="" className="social-media" />
									<img src={twitterLogo} alt="" className="social-media" /> */}
							<a
								href="https://in.linkedin.com/company/ant-philosophy-and-technology-services-private-limited"
								target="_blank"
								aria-label='AntPhy | LinkedIn'
							>
								<img
									src={linkdinLogo}
									alt="LinkedIn Logo"
									className="social-media"
									width={25}
									height={25}
								/>
							</a>
							<a
								href="https://twitter.com/antphytech"
								target='_blank'
								aria-label='AntPhy | Twitter'
							>
								<img
									src={twitterLogo}
									alt="Twitter Logo"
									className="social-media"
									width={25}
									height={25}
								/>
							</a>
							<a
								href="https://www.facebook.com/antphytech "
								target='_blank'
								aria-label='AntPhy | Facebook'
							>
								<img
									src={facebookLogo}
									alt="Facebook Logo"
									className="social-media"
									width={25}
									height={25}
								/>
							</a>
							{/* <a href="#" target='_blank' aria-label='AntPhy | Instagram'>
								<img
									src={instaLogo}
									alt="Instagram Logo"
									className="social-media"
									width={25}
									height={25}
								/>
							</a> */}
						</div>
					</div>
				</div>
				{/* mobile view */}
				<div className="flex justify-between items-center footer-content mobile">
					<div className=" flex justify-center items-center flex-col">
						<div className="pt-5">
							<p className="table-title"> COMPANY</p>
							<a href="" aria-label='About Us' smooth onClick={handleAboutUsClick}>
								<p className="table-data">ABOUT US</p>
							</a>
							<a href="" smooth onClick={handelJoinUs} aria-label='Join Us'>
								<p className="table-data">JOIN US</p>
							</a>
						</div>
						<div className="pt-5">
							<Link to="/products">
								<p className="table-title"> PRODUCTS</p>
							</Link>
							<Link to="/services">
								<p className="table-data">SERVICES</p>
							</Link>
							<Link to="/industry">
								<p className="table-data">INDUSTRY</p>
							</Link>
						</div>
						<div className="pt-5">
							<Link to="/contact-us">
								<p className="table-title"> CONTACT US</p>
							</Link>
						</div>
						<div className=" flex justify-center items-center flex-col mb-5">
							<div className=" flex">
								<img src={whatsappLogo} alt="WhatsApp Logo" width={15} height={15} />
								<a
									href="https://wa.me/+918660374515"
									target="_blank"
									className="table-data"
									aria-label='AntPhy | WhatsApp'
								>
									8660374515
								</a>
							</div>

							<div className=" flex">
								<img src={emailLogo} alt="Email Logo" width={15} height={15} />
								<a
									href="mailto:reachus@antphy.com"
									target="_blank"
									className="table-data"
									aria-label='AntPhy | Email'
								>
									reachus@antphy.com
								</a>{' '}
							</div>
						</div>
						<div className="pt-5">
							<Link>
								<p className="table-title">REGD ADDRESS </p>
							</Link>
						</div>
						<div className='flex justify-center items-center flex-col mb-5'>
							<div className="flex flex-col">
								<div className='flex align-start'>
									<img src={addressLogoMobile} alt="Address Icon" width={15} height={15} />
									<span className='address-text text-align-center'>
										Ant Philosophy Technology and Services Pvt. Ltd. <br />
										B-301, SLS Spring Woods <br />
										Silver County Road Off- Haralur Main Road <br />
										Bengaluru Karnataka 560102 India
									</span>
									{/* <div>
										<p className='address-text'>Ant Philisophy Technology and Services Pvt. Ltd.</p>
										<p className='address-text'>B-301, SLS Spring Woods</p>
										<p className='address-text'>Silver County Road Off- Haralur Main Road</p>
										<p className='address-text'>Bengaluru Karnataka 560102 India</p>
									</div> */}
								</div>
								
							</div>
						</div>
					</div>
					<div>
						<div className=" flex justify-between items-center">
							<div>
								<Link to="/" onClick={scrollTop}>
									<img src={footerLogo} alt="Footer Logo" width={100} />
								</Link>
								{/* <div>
									<p>Ant Philosophy Technology and Services Pvt. Ltd.</p>
									<p>B-301, SLS Spring Woods</p>
									<p>Silver County Road Off- Haralur Main Road</p>
									<p>Bengaluru Karnataka 560102 India</p>
								</div> */}
							</div>
							<div className=" flex justify-center items-center flex-col">
								<div>
									<img
										src={footerIllustartion}
										alt="Footer Illustration"
										width={100}
										height="auto"
									/>
								</div>
								<div className=" flex justify-center items-center pt-5 flex-row">
									{/* <img src={instaLogo} alt="" className="social-media" />
									<img src={twitterLogo} alt="" className="social-media" /> */}
									<a
										href="https://in.linkedin.com/company/ant-philosophy-and-technology-services-private-limited"
										target="_blank"
										aria-label='AntPhy | LinkedIn'
									>
										<img
											src={linkdinLogo}
											alt="LinkedIn Logo"
											className="social-media"
											width={25}
											height={25}
										/>
									</a>

									<a
										href="https://twitter.com/antphytech"
										target='_blank'
										aria-label='AntPhy | Twitter'
									>
										<img
											src={twitterLogo}
											alt="Twitter Logo"
											className="social-media"
											width={25}
											height={25}
										/>
									</a>
									<a
										href="https://www.facebook.com/antphytech "
										target='_blank'
										aria-label='AntPhy | Facebook'
									>
										<img
											src={facebookLogo}
											alt="Facebook Logo"
											className="social-media"
											width={25}
											height={25}
										/>
									</a>
									{/* <a
										href=""
										target='_blank'
										aria-label='AntPhy | Instagram'
									>
										<img
											src={instaLogo}
											alt="Instagram Logo"
											className="social-media"
											width={25}
											height={25}
										/>
									</a> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className=" border"></div>
				<div className=" flex justify-center items-center pt-5">
					<img src={reservedLogo} alt="Copyright Logo" />
					<p className=" font-Exo2Regular text-sm text-bgBlack pl-1 ">
						Ant Philosophy. All rights reserved.
					</p>
				</div>
			</div>
		</div>
	);
}

export default Footer;
