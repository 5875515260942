import companyLogoSrc from '../Images/Group162.png';
import companyLogoTextSrc from '../Images/COMPANY-LOGO.png';

export const ICONS = {
	rightArrow: '../Images/rigthArrow.png',
	leftArrow: '../Images/leftArrow.png',
};

export const testimonialDataobject = [
	{
		companyLogo: companyLogoSrc,
		companyLogoText: companyLogoTextSrc,
		content:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod nulla ac mi hendrerit, eget consequat dolor interdum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quidem eaque delectus animi omnis id dolores repellat provident ut et voluptate distinctio officia temporibus sint praesentium architecto illum, harum repellendus.',
		companyName: 'Company A',
		fullName: 'John Doe',
	},
	{
		companyLogo: companyLogoSrc,
		companyLogoText: companyLogoTextSrc,

		content:
			'Sed commodo justo eu sapien dapibus, a vehicula tellus suscipit. Integer nec tortor felis. Vestibulum pharetra odio id quam accumsan, eu efficitur nisi convallis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quidem eaque delectus animi omnis id dolores repellat provident ut et voluptate distinctio officia temporibus sint praesentium architecto illum, harum repellendus.',
		companyName: 'Company B',
		fullName: 'Jane Smith',
	},
	// Add more testimonials as needed
];
