import React, { useEffect, useRef } from 'react';
import {
	dataAiData,
	dataAiServicesData,
} from '../Assets/Utils/ServicePageData';
import dataAi_illustration from '../Assets/Images/data_ai.svg';
import dataAnalytics from '../Assets/Images/data_strategy (1).svg';
import naturalLanguage from '../Assets/Images/natural_language (1).svg';
import dataStrategy from '../Assets/Images//data_strategy (1).svg';
import ai_ml_model from '../Assets/Images/ai_ml_model.svg';
import predictiveAnalysis from '../Assets/Images/predictive_analytic (1).svg';
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Helmet } from 'react-helmet-async';

export default function DataAi() {
	useEffect(() => {
		window.scrollTo(0, 0);
		tl.from(two.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: two.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(dataTitle.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: dataTitle.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(dataTitleIllus.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: dataTitleIllus.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(data1.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: data1.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(data2.current, 4, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: data2.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
	}, []);

	gsap.registerPlugin(ScrollTrigger);

	const tl = gsap.timeline();

	const two = useRef(null);
	const dataTitle = useRef(null);
	const dataTitleIllus = useRef(null);
	const data1 = useRef(null);
	const data2 = useRef(null);
	const data3 = useRef(null);
	const data4 = useRef(null);
	const data5 = useRef(null);

	return (
		<>
			<Helmet>
				<title>Data & AI</title>
				<link rel="canonical" href="https://www.antphy.com/data-ai" />
				<meta
					name='description'
					content='The quantum of information available at our disposal today is overwhelming, and exactly here, we have begun our journey. We sort through this mountain of data to find the insights that truly matter. We give our customers easy access to important Business Intelligence, future predictions, and useful recommendations.'
				/>
			</Helmet>
		<div
			className=" pt-20 pb-10 flex justify-center items-center bg-bgBlack flex-col mt-20"
			id="data-ai"
		>
			<div className="bg-blur pt-10 pb-10" ref={dataTitleIllus}>
				<img src={dataAi_illustration} alt="Data & AI Illustration" width={430} height={472.5} />
			</div>
			<div>
				<p className=" num" ref={two}>
					02
				</p>
			</div>
			<div>
				<p className=" title-white pb-10 pt-4"> {dataAiData.TITLE}</p>
			</div>

			<div className=" w-full flex justify-center items-center flex-col">
				<div className=" w-3/4 body-padding">
					<p className=" para-white text-center ">{dataAiData.P1}</p>
					<p className=" para-white text-center body-padding">
						{dataAiData.P2}
					</p>
				</div>

				<div className=" p-10 flex justify-center items-center flex-wrap">
					<div
						ref={data1}
						className=" flex justify-center items-center flex-wrap"
					>
						<div className="box-black relative">
							<img
								src={dataAnalytics}
								alt="Data Analytics"
								className=" absolute w-full h-full"
							/>
							<p className=" para-white text-center">
								{dataAiServicesData.SERVICES1}
							</p>
						</div>
						<div className="box-black relative">
							<img
								src={predictiveAnalysis}
								alt="Predective Analysis"
								className="  absolute w-full h-full"
							/>
							<p className=" para-white text-center">
								{dataAiServicesData.SERVICE2}
							</p>
						</div>
						<div className="box-black relative">
							<img
								src={naturalLanguage}
								alt="Natural Language"
								className="  absolute w-full h-full"
							/>
							<p className=" para-white text-center">
								{dataAiServicesData.SERVICE3}
							</p>
						</div>
					</div>
					<div ref={data2} className=" flex justify-center items-center">
						<div className="box-black relative">
							<img
								src={dataStrategy}
								alt="Data Strategy"
								className=" absolute w-full h-full"
							/>
							<p className=" para-white text-center">
								{dataAiServicesData.SERVICE4}
							</p>
						</div>
						<div className="box-black relative">
							<img
								src={ai_ml_model}
								alt="AI & ML Model"
								className="  absolute w-full h-full"
							/>
							<p className=" para-white text-center">
								{dataAiServicesData.SERVICE5}
							</p>
						</div>
					</div>
				</div>
			</div>
			</div>
			</>
	);
}
