import React, { useEffect, useRef, useState } from 'react';
import '../Assets/Styles/Contact.css';
// import modalBg from '../Assets/Images/contact-modal-bg.svg';
import modalIllus from '../Assets/Images/modal_illus.svg';
import contactIllustation from '../Assets/Images/contact4x.png';
import reachUsIllus from '../Assets/Images/contact_illus_black.svg';
import contactBg from '../Assets/Images/contact_us_blue_box.svg';
import whatsappLogo from '../Assets/Images/whatsapp.svg';
import emailLogo from '../Assets/Images/email.svg';
import { ContactHero } from '../Assets/Utils/ConatactPage';
import { formData } from '../Assets/Utils/HomePageData';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap, Power3 } from 'gsap';
import axios from 'axios';
import {
	ArrowDownIcon,
	ArrowRightIcon,
	ChevronDownIcon,
	ChevronRightIcon
} from '@heroicons/react/24/solid';
import { Helmet } from 'react-helmet-async';


function Contact() {
	const [submitModal, setSubmitModal] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [selected, setIsSelected] = useState("Choose one");
	const [error, setError] = useState(0);
	const [contactData, setContactData] = useState({
		firstName: '',
		lastName: '',
		companyName: '',
		companySize: '',
		text: '',
	});
	console.log(contactData);
	console.log(error);
	const contactIllus = useRef(null);
	const contactTitle = useRef(null);
	const form = useRef(null);
	gsap.registerPlugin(ScrollTrigger);
	const tl = gsap.timeline();
	const box1 = useRef(null);

	useEffect(() => {
		tl.from(box1.current, 2, { opacity: 0, x: '300', ease: Power3.easeOut });
	}, []);

	const handelError = (e) => {
		e.preventDefault();
		if (contactData.firstName.length === 0) {
			setError(1);
		} else if (contactData.lastName.length === 0) {
			setError(2);
		} else if (contactData.companyName.length === 0) {
			setError(3);
		}
		// else if (contactData.companySize.length === 0) {
		// 	setError(4);
		// }
		else if (contactData.text.length === 0) {
			setError(5);
		} else {
			setError(0);
			contactUs(e);
		}
	};

	const contactUs = async () => {
		const mailData = {
			to: 'reachus@antphy.com',
			from: 'datatestingdummy@gmail.com',
			subject: `${contactData.companyName} wants to contact us `,
			text: `Hi there, 
	
	${contactData.firstName} ${contactData.lastName}  from ${contactData.companyName} (Company size ${contactData.companySize || 'Not Applicable'}) wants to connect. They said:
	
	${contactData.text}`,
		};
		try {
			const response = await axios
				.post(
					'https://us-central1-antphy-dev.cloudfunctions.net/app/send-mail',
					mailData
				)
				.then(() => {
					setSubmitModal(true);
					setContactData({
						firstName: '',
						lastName: '',
						companyName: '',
						companySize: '',
						text: '',
					});
				});
		} catch (error) {
			console.log(error);
		}
	};
	// const customStyles = {
	// 	control: (base, state) => ({
	// 		...base,
	// 		background: '#000000', // Black background
	// 		color: '#FFFFFF', // White font color
	// 		padding: '15px', // Adjust padding as needed
	// 		width: '93%', // Set the width
	// 		borderRadius: state.isFocused ? '3px 3px 0 0' : '3px', // Rounded corners
	// 		borderColor: state.isFocused ? 'skyblue' : 'black', // Border color
	// 		'&:hover': {
	// 			borderColor: state.isFocused ? 'black' : 'skyblue', // Hover effect
	// 		},
	// 	}),
	// 	// Add other styles as needed (e.g., menu, menuList, etc.)
	// };
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top when the component mounts

		tl.from(contactIllus.current, 1, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
		});
		tl.from(contactTitle.current, 1, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
		});
		tl.from(form.current, 4, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: form.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
	}, []);
	return (
		<>
			<Helmet>
				<title>Contact Us</title>
				<link rel="canonical" href="https://www.antphy.com/contact-us" />
				<meta
					name='description'
					content='Get in touch with us.'
				/>
			</Helmet>
		<div className=" bg-bgBlack overflow-hidden relative">
			{/* <div className=" flex flex-col justify-center items-center bg-blur">
				<div className=" pt-20 pb-10 mt-40" ref={contactIllus}>
					<img
						src={contactIllustation}
						alt=""
						width={363.293}
						height={257.962}
					/>
				</div>
				<div
					className=" flex flex-col justify-center items-center"
					ref={contactTitle}
				>
					<p className=" title-white">{ContactHero.TITLE}</p>
					<p className=" para-white">{ContactHero.SUBTITLE}</p>
				</div>
			</div> */}
			{/* <div iv className="contact-form">
				<div className=" flex justify-center items-center flex-col py-10 px-20">
					<p className="title-white text-center">{formData.TITLE}</p> */}
			{/* <p className="para-white">{formData.SUBTITLE}</p> */}
			{/* </div>
			</div> */}
			<div className=" my-56 bg-lightBlue p-10   bg-blur font-Exo2Regular slanted-bg pb-14 ">
				<div className="form-slant-content ">
					{/* <div className="form-illus">
						<img src={reachUsIllus} alt="" width={350} height={350} />
						<div className=" mt-[20px]">
							<p className="title-black">OR REACH US:</p>
							<div className=" flex flex-col justify-center items-center">
								<div className=" flex justify-start items-center pt-5">
									<img src={whatsappLogo} alt="" width={18} height={18} />
									<a
										href="https://wa.me/+918660374515"
										target="_blank"
										className=" pl-2 text-text20"
									>
										8660374515
									</a>
								</div>
								<div className=" flex justify-start items-center pt-2">
									<img src={emailLogo} alt="" width={18} height={18} />
									<p className=" pl-2 text-text20">
										<a href="reachus@antphy.com" target="_blank">
											reachus@antphy.com
										</a>
									</p>
								</div>
							</div>
						</div>
					</div> */}
					<div className=" talk-to-us ">
						<img
							src={reachUsIllus}
							alt="Reach Us Illustration"
							width={350}
							height={350}
							className="reachUsIllus"
						/>
						<div className=" flex justify-center items-center flex-col">
							<p className=" mt-5 title-black">{ContactHero.TITLE}</p>
							<p className=" para-black">{ContactHero.SUBTITLE}</p>
						</div>
					</div>
					<form
						className=" bg-bgBlack form  font-Exo2Regular  rounded-[40px] mt-5"
						ref={box1}
					>
						<div className=" flex flex-col ">
							<label htmlFor="firstName" className=" form-white">
								{formData.FIRST_NAME}
							</label>
							<input
								value={contactData.firstName}
								id="firstName"
								type="text"
								placeholder="First Name"
								className=" bg-transparent border-2 border-white500 p-3 mt-3 rounded-full form-white"
								onChange={(e) => {
									setContactData({
										...contactData,
										firstName: e.target.value,
									});
								}}
							/>
							{error === 1 ? (
								<p className="error ">** Enter First Name **</p>
							) : null}
						</div>
						<div className=" flex flex-col mt-5">
							<label htmlFor="lastName" className=" form-white">
								{formData.LAST_NAME}
							</label>
							<input
								value={contactData.lastName}
								id="lastName"
								type="text"
								placeholder="Last Name"
								className=" bg-transparent border-2 border-white500 p-3 mt-3 rounded-full form-white"
								onChange={(e) => {
									setContactData({
										...contactData,
										lastName: e.target.value,
									});
								}}
							/>
							{error === 2 ? (
								<p className="error ">** Enter Last Name **</p>
							) : null}
						</div>
						<div className=" flex flex-col mt-5">
							<label htmlFor="companyName" className=" form-white">
								{formData.COMPANY_NAME}
							</label>
							<input
								value={contactData.companyName}
								id="companyName"
								type="text"
								placeholder="Company Name"
								className=" bg-transparent border-2 border-white500 p-3 mt-3 rounded-full form-white"
								onChange={(e) => {
									setContactData({
										...contactData,
										companyName: e.target.value,
									});
								}}
							/>
							{error === 3 ? (
								<p className="error ">** Enter Company Name **</p>
							) : null}
						</div>
						<div className=" flex flex-col mt-5">
							<label htmlFor="companySize" className=" form-white">
								{formData.COMPANY_SIZE}
							</label>
							{/* <input
								type="text"
								id="companySize"
								placeholder="Company Size"
								className=" bg-transparent border-2 border-white500 p-3 mt-3 rounded-full form-white"
								value={contactData.companySize}
								onChange={(e) => {
									setContactData({
										...contactData,
										companySize: e.target.value,
									});
								}}
							/> */}
							{/* <Dropdown options={[1, 2, 3]} placeholder={'Company Size'} styles={customStyles} /> */}



							<div className="bg-transparent border-2 border-white500 p-3 mt-3 rounded-full form-white dropdown">
								<div
									onClick={(e) => {
										setIsActive(!isActive);
									}}
									className="dropdown-btn"
								>
									{selected}
									{/* <span
										className={isActive ? "fas fa-caret-up" : "fas fa-caret-down"}
									/> */}
									{isActive ? <ChevronRightIcon color="white"
										width={20}
										height={20}
									/> : <ChevronDownIcon
										width={20}
										height={20}
										color='white'
									/>}
								</div>
								<div
									className="dropdown-content"
									style={{ display: isActive ? "block" : "none" }}
								>
									<div
										onClick={(e) => {
											setIsSelected(e.target.textContent);
											setContactData({
												...contactData,
												companySize: e.target.textContent
											})
											setIsActive(!isActive);
										}}
										className="item"
									>
										Not Applicable
									</div>
									<div
										onClick={(e) => {
											setIsSelected(e.target.textContent);
											setContactData({
												...contactData,
												companySize: e.target.textContent
											})
											setIsActive(!isActive);
										}}
										className="item"
									>
										10 - 50
									</div>
									<div
										className="item"
										onClick={(e) => {
											setIsSelected(e.target.textContent);
											setContactData({
												...contactData,
												companySize: e.target.textContent
											})
											setIsActive(!isActive);
										}}
									>
										51 - 200
									</div>
									<div
										className="item"
										onClick={(e) => {
											setIsSelected(e.target.textContent);
											setContactData({
												...contactData,
												companySize: e.target.textContent
											})
											setIsActive(!isActive);
										}}
									>
										201 - 500
									</div>
									<div
										className="item"
										onClick={(e) => {
											setIsSelected(e.target.textContent);
											setContactData({
												...contactData,
												companySize: e.target.textContent
											})
											setIsActive(!isActive);
										}}
									>
										500+
									</div>

								</div>
							</div>

							{/* <select id='companySize' className='bg-transparent border-2 border-white500 p-3 mt-3 rounded-full form-white'>
								<option value={'1-25'}>1 to 25</option>
								<option value={'50+'}>25 to 80</option>
								<option value={'100+'}>100+</option>
								<option>&gt;100</option>
							</select> */}

							{error === 4 ? (
								<p className="error ">** Enter Company Size **</p>
							) : null}
						</div>
						<div className=" flex flex-col  mt-5 ">
							<label htmlFor="message" className=" form-white">
								{formData.HOW_CAN_WE_HELP}
							</label>
							<textarea
								id="message"
								className=" bg-transparent border-2 border-white500 p-3 mt-3 rounded-xl h-[150px]  form-white"
								value={contactData.text}
								onChange={(e) => {
									setContactData({
										...contactData,
										text: e.target.value,
									});
								}}
							/>
							{error === 5 ? (
								<p className="error ">** Enter Message **</p>
							) : null}
						</div>
						<div className="flex justify-start items-center mt-8">
							<button
								className="blue-btn"
								onClick={(e) => {
									handelError(e); // Validate the form
								}}
							>
								<p className="text-bgBlack  ">SUBMIT</p>
							</button>
						</div>
					</form>
				</div>
			</div>
			{submitModal && (
				<div className="modal ">
					{/* <p>Hello</p> */}
					<div className="modal-content  ">
						<div>
							<img src={modalIllus} alt="Modal Illustration" width={200} height={200} />
						</div>
						<div className=" para-black pt-5">
							<p>Thanks for submitting your question.</p>
							<p>We will contact you soon.</p>
						</div>
						<button
							className="submit-btn mt-10"
							onClick={() => {
								setSubmitModal(false);
							}}
						>
							<p className="para-white">CLOSE</p>
						</button>
					</div>
				</div>
			)}
			</div>
			</>
	);
}

export default Contact;
