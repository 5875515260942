import React, { useEffect, useReducer, useRef } from 'react';
import '../Assets/Styles/Global.css';
import industryIllustration from '../Assets/Images/industryIllustartion.png';
import btn_bg from '../Assets/Images/btn-bg.png';
import '../Assets/Styles/Industry.css';
import { gsap, Power3 } from 'gsap';
import { industrtData } from '../Assets/Utils/IndustriesPageData';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function Industries() {
	gsap.registerPlugin(ScrollTrigger);
	const tl = gsap.timeline();

	const industryTitle = useRef(null);
	const industryIllus = useRef(null);
	const industrySubtitle = useRef(null);
	const industryText = useRef(null);
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top when the component mounts

		tl.from(industryTitle.current, 3, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
		});
		tl.from(industryIllus.current, 3, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
		});
		tl.from(industrySubtitle.current, 3, {
			opacity: 0,
			x: '-300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: industrySubtitle.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
		tl.from(industryText.current, 3, {
			opacity: 0,
			x: '300',
			ease: Power3.easeOut,
			scrollTrigger: {
				trigger: industryText.current,
				start: 'top 80%',
				end: 'bottom 80%',
				scrub: 1,
			},
		});
	});
	return (
		<>
			<Helmet>
				<title>Industries</title>
				<link rel="canonical" href="https://www.antphy.com/industry" />
				<meta
					name='description'
					content={'At Ant Philosophy, our industry-agnostic approach is our strength. We don\'t just provide technology solutions; we ADAPT, MASTER, and INNOVATE to deliver optimal outcomes for our clients, regardless of their industry.'}
				/>
			</Helmet>
		<div className=" bg-bgBlack w-full overflow-hidden">
			<div className="industry bg-blur">
				<p
					className="industry-title text-textWhite font-Exo2Regular font-bold"
					ref={industryTitle}
				>
					{industrtData.TITLE}
				</p>
				<img src={industryIllustration} alt="Industry Illustration" ref={industryIllus} />
			</div>
			<div className=" w-full flex flex-col justify-center items-center bg-blur	">
				<div className=" w-3/4 flex flex-col justify-center items-center pb-20">
					{/* <p className=" title-white" ref={industrySubtitle}>
						{industrtData.SUBTITLE}
					</p> */}
					<div ref={industryText}>
						<p className=" para-white text-center">{industrtData.P1}</p>
						<p className=" para-white pt-10 text-center">{industrtData.P2}</p>
					</div>
				</div>
				<Link to="/contact-us">
					<div className=" bg-lightBlue rounded-3xl mb-20">
						<button className=" btn-blue">GET STARTED</button>
					</div>
				</Link>
			</div>
			</div>
			</>
	);
}

export default Industries;
