import React, { useEffect, useRef, useState } from 'react';
import '../Styles/Navbar.css';
import antLogo from '../Images/HOMEPAGE_LOGO.svg';
import { navBarData } from '../Utils/HomePageData';
import { Link, useNavigate, useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { animateScroll as scroll } from 'react-scroll';

import {
	ArrowDownIcon,
	ChevronDownIcon,
	MagnifyingGlassIcon,
	XCircleIcon,
	XMarkIcon,
} from '@heroicons/react/24/solid';
import hamburger_menu from '../Images/hamburger_menu.png';

function Navbar({ handelClick }) {
	const [dropdown, setDropdown] = useState(false);
	const [responsive, setResponsive] = useState(false);
	const navigate = useNavigate(); // Create a navigate function

	const handleAboutUsClick = (e) => {
		e.preventDefault();
		// const isHomePage = window.location.pathname === '/';

		// if (isHomePage) {
		// 	scrollToAbout('about-us');
		// } else {
		navigate('/about-us');
		setTimeout(() => {
			scrollToAbout('about-us');
		}, 500);
		// }
	};

	const handleServiceClick = (sectionId) => {
		// e.preventDefault();
		setDropdown(false);

		const isServicePage = window.location.pathname === '/services';

		if (isServicePage) {
			scrollToAbout(sectionId);
		} else {
			navigate('/services');
			setTimeout(() => {
				scrollToAbout(sectionId);
			}, 500);
		}
	};

	const scrollToAbout = (sectionId) => {
		const section = document.getElementById(sectionId);
		if (section) {
			window.scrollTo({
				top: section.offsetTop,
				behavior: 'smooth',
			});
		}
		// const isHomePage = window.location.pathname === '/home';

		// const currentPath = isHomePage ? '/home' : window.location.pathname;
		// const newUrl = `${currentPath}/${sectionId}`;
		// navigate(newUrl);
	};

	function remove_hash_from_url() {
		console.log('Removing hash...');
		var uri = window.location.toString();
		setDropdown(false);

		if (uri.indexOf('#') > 0) {
			var clean_uri = uri.substring(0, uri.indexOf('#'));
			console.log('Clean URI:', clean_uri);

			window.history.replaceState({}, document.title, clean_uri);
		}
	}

	const drop = () => {
		setDropdown(false);
	};

	const scrollTop = () => {
		scroll.scrollToTop();
	};

	const toggleDrowdown = () => {
		setDropdown((prevState) => !prevState);
	};

	function scrollToSection(sectionId) {
		const section = document.getElementById(sectionId);

		if (section) {
			section.scrollIntoView({ behavior: 'smooth' });
		}
	}

	const toggleNav = (a) => {
		setResponsive(a);
	};

	// const handelAboutUs = () => {
	// 	// if (elementRef.current) {
	// 	elementRef.current.scrollIntoView({ behavior: 'smooth' });
	// 	// }
	// };

	// function navigateAndScrollToSection(sectionId) {
	// 	// First, navigate to the home page
	// 	window.location.href = '/';

	// 	// Then, scroll to the specified section
	// 	setTimeout(function () {
	// 		const section = document.getElementById(sectionId);
	// 		if (section) {
	// 			section.scrollIntoView({ behavior: 'smooth' });
	// 		}
	// 	}, 1000); // You can adjust the delay as needed
	// }

	const aboutUsNav = (e) => {
		console.log(e.target.value);
		// navigate('/'); // Navigate to the home page
		// setTimeout(() => {
		// 	const sectionId = 'aboutUs';
		// 	const section = document.getElementById(sectionId);
		// 	if (section) {
		// 		section.scrollIntoView({ behavior: 'smooth' });
		// 	}
		// }, 1000);
	};

	return (
		<div className=" p-7 pl-24 pr-24 w-screen h-auto fixed top-0 left-0 bg-bgBlack z-10 nav-res ">
			<div className=" flex justify-between items-center main">
				<div>
					<Link
						to="/"
						onClick={() => {
							drop();
							scrollTop();
						}}
					>
						<img src={antLogo} alt="AntPhy Logo" width={110} />
					</Link>
				</div>
				<div className=" text-textWhite font-Exo2Regular">
					<ul className="flex">
						{/* <Link
							to="/"
							onClick={() => {
								drop();
								scrollTop();
							}}
						>
							<li className="Nav-list">{navBarData.HOME}</li>
						</Link> */}

						<a href="" aria-label='About Us' smooth onClick={handleAboutUsClick}>
							<li className="Nav-list" value="aboutUs">
								{navBarData.ABOUT_US}
							</li>
						</a>
						{/* <li className="" value on="aboutUs">
							<button
								onClick={() => {
									handelClick();
								}}
							>
								{navBarData.ABOUT_US}
							</button>
						</li> */}

						{/* </Link> */}

						<div className=" flex flex-col justify-center items-center">
							<div className=" flex justify-center items-center">
								<Link to="/services">
									<li
										className="Nav-list flex justify-center items-center  relative"
										onClick={() => {
											scrollTop();
										}}
									>
										<p className=" pr-3">{navBarData.SERVICES}</p>
									</li>
								</Link>
								<ChevronDownIcon
									color="white"
									width={20}
									height={20}
									onClick={() => {
										toggleDrowdown();
									}}
								/>
							</div>

							<div className="absolute top-[80px]">
								{dropdown && (
									<div className="  flex justify-center items-start flex-col 	 pl-28 text-[14px]">
										<Link
											to="/blockchain"
											onClick={() => {
												// handleServiceClick('blockchain');
												toggleDrowdown();
											}}
										>
											{' '}
											<li className=" bg-textWhite pt-1 pb-1 pr-7 pl-7 text-bgBlack rounded-full hover:bg-lightBlue hover:text-textWhite">
												BLOCKCHAIN
											</li>
										</Link>
										{/* <a
											href=""
											// to="/services/#blockchain"
											smooth
											onClick={() => {
												handleServiceClick('blockchain');
											}}
										>
											<li className=" bg-textWhite pt-1 pb-1 pr-7 pl-7 text-bgBlack rounded-full hover:bg-lightBlue hover:text-textWhite">
												BLOCKCHAIN
											</li>
										</a> */}
										<Link
											to="/data-ai"
											onClick={() => {
												// drop();
												// handleServiceClick('/services-data-ai');
												toggleDrowdown();
											}}
										>
											<li className=" bg-textWhite pt-1 pb-1 pr-7 pl-7 text-bgBlack rounded-full mt-3 hover:bg-lightBlue hover:text-textWhite">
												DATA & AI
											</li>
										</Link>
										{/* <a
											href=""
											smooth
											onClick={() => {
												// drop();
												handleServiceClick('data-ai');
											}}
										></a> */}
										<Link
											to="/system-integrations"
											onClick={() => {
												toggleDrowdown();
											}}
										>
											<li className=" bg-textWhite pt-1 pb-1 pr-7 pl-7 text-bgBlack	 rounded-full mt-3 hover:bg-lightBlue hover:text-textWhite">
												SYSTEM INTEGRATIONS
											</li>
										</Link>
										{/* <a
											href=""
											smooth
											onClick={() => {
												handleServiceClick('system-integraions');
												// drop();
											}}
										>
											<li className=" bg-textWhite pt-1 pb-1 pr-7 pl-7 text-bgBlack	 rounded-full mt-3 hover:bg-lightBlue hover:text-textWhite">
												SYSTEM INTEGRATIONS
											</li>
										</a> */}
									</div>
								)}
							</div>
						</div>
						<Link
							to="/industry"
							onClick={() => {
								drop();
								scrollTop();
							}}
						>
							<li className="Nav-list">{navBarData.INDUSTRY}</li>
						</Link>
						<Link
							to="/products"
							onClick={() => {
								drop();
								scrollTop();
							}}
						>
							<li className="Nav-list">{navBarData.PRODUCTS}</li>
						</Link>
						<Link
							to="/contact-us"
							onClick={() => {
								drop();
								scrollTop();
							}}
						>
							<li className="Nav-list">{navBarData.CONTACT_US}</li>
						</Link>
					</ul>
				</div>
			</div>
			<div className="responsive ">
				<div className=" flex justify-between items-center nav-res">
					<div>
						<Link
							to="/"
							onClick={() => {
								scrollTop();
								setResponsive(false);
							}}
						>
							<img src={antLogo} alt="AntPhy Logo" />
						</Link>
					</div>
					<div>
						<img
							src={hamburger_menu}
							alt="Hamburger Icon"
							onClick={() => {
								setResponsive(true);
							}}
						/>
						{responsive && (
							<div className=" w-full h-screen bg-bgBlack absolute top-0 left-0 pt-7 pb-7 pr-24 pl-24 bg-blur nav-res ">
								<div className=" flex justify-between items-center nav-res">
									<Link
										to="/"
										onClick={() => {
											scrollTop();
											setResponsive(false);
										}}
									>
										<img src={antLogo} alt="AntPhy Logo" />
									</Link>
									<button
										onClick={() => {
											setResponsive(false);
										}}
									>
										<XMarkIcon color="white" width={30} height={30} />
									</button>
								</div>
								<div>
									<ul className=" flex flex-col justify-start items-start font-Exo2Regular text-text20 text-textWhite pt-10 nav-res">
										{/* <Link
											to="/"
											onClick={() => {
												toggleNav(false);
												drop();
												scrollTop();
											}}
										>
											<li className="Nav-list-res">{navBarData.HOME}</li>
										</Link> */}
										<a
											aria-label='About Us'
											href=""
											smooth
											onClick={(e) => {
												handleAboutUsClick(e);
												toggleNav(false);
											}}
										>
											<li className="Nav-list-res" value="aboutUs">
												{navBarData.ABOUT_US}
											</li>
										</a>
										{/* <button>
											<li className="Nav-list-res" value on="aboutUs">
												{navBarData.ABOUT_US}
											</li>
										</button> */}

										<Link to="/services">
											<div className=" ">
												<li className="Nav-list-res flex justify-start items-center ">
													<p
														className=" pr-3"
														onClick={() => {
															toggleNav(false);
														}}
													>
														{navBarData.SERVICES}
													</p>
													<Link to="/services">
														<ChevronDownIcon
															color="white"
															width={20}
															height={20}
															onClick={() => {
																toggleDrowdown();
																setResponsive(true);
																scrollTop();
															}}
														/>
													</Link>
												</li>
												<div className="">
													{dropdown && (
														<div className="  flex justify-center items-start flex-col  text-[14px]">
															<Link
																to="/blockchain"
																smooth
																onClick={() => {
																	// drop();
																	toggleNav(false);
																	// handleServiceClick('blockchain');
																}}
															>
																<li className=" bg-textWhite pt-1 pb-1 pr-7 pl-7 text-bgBlack rounded-full hover:bg-lightBlue hover:text-textWhite">
																	BLOCKCHAIN
																</li>
															</Link>
															{/* <a smooth></a> */}
															<Link
																to="/data-ai"
																smooth
																onClick={() => {
																	// drop();
																	toggleNav(false);
																	// handleServiceClick('data-ai');
																}}
															>
																<li className=" bg-textWhite pt-1 pb-1 pr-7 pl-7 text-bgBlack rounded-full mt-3 hover:bg-lightBlue hover:text-textWhite">
																	DATA & AI
																</li>
															</Link>
															<Link
																to="system-integrations"
																smooth
																onClick={() => {
																	// drop();
																	toggleNav(false);
																	handleServiceClick('system-integraions');
																}}
															>
																<li className=" bg-textWhite pt-1 pb-1 pr-7 pl-7 text-bgBlack	 rounded-full mt-3 hover:bg-lightBlue hover:text-textWhite">
																	SYSTEM INTEGRATIONS
																</li>
															</Link>
															<a></a>
														</div>
													)}
												</div>
											</div>
										</Link>
										<Link
											to="industry"
											onClick={() => {
												toggleNav(false);
												drop();
												scrollTop();
											}}
										>
											<li className="Nav-list-res">{navBarData.INDUSTRY}</li>
										</Link>
										<Link
											to="products"
											onClick={() => {
												toggleNav(false);
												drop();
												scrollTop();
											}}
										>
											<li className="Nav-list-res">{navBarData.PRODUCTS}</li>
										</Link>
										<Link
											to="contact-us"
											onClick={() => {
												toggleNav(false);
												drop();
												scrollTop();
											}}
										>
											<li className="Nav-list-res">{navBarData.CONTACT_US}</li>
										</Link>
									</ul>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Navbar;
